/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Location } from 'history';
import { lazy } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import Meta from 'SourceComponent/Meta';
import { PrintTypes } from 'SourceComponent/MyAccountOrderPrint/MyAccountOrderPrint.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    RouterComponent as SourceRouterComponent,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
import { RouterItemType, RouterSwitchItemType } from 'SourceComponent/Router/Router.config';
import UrlRewrites from 'SourceRoute/UrlRewrites';
import { MyAccountTabs } from 'SourceType/Account.type';
import { ReactElement } from 'SourceType/Common.type';
import history from 'SourceUtil/History';
import { HistoryState } from 'SourceUtil/History/History.type';

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    WishlistShared,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    StyleGuidePage,
    OrderPrintPage,
    Header,
    NavigationTabs,
    Footer,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    CookiePopup,
    DemoNotice,
    SomethingWentWrong,
    Breadcrumbs,
    withStoreRegex,
};

export const MenPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-men" */ 'Route/Home/Men'));
export const WomenPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-women" */ 'Route/Home/Women'));
export const RunPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-run" */ 'Route/Home/Running'));
export const GymPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-gym" */ 'Route/Home/Gym'));
export const WalkPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-walk" */ 'Route/Home/Walk'));

export const MenRunPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-men-run" */ 'Route/Home/Men/Run'));
export const MenGymPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-men-gym" */ 'Route/Home/Men/Gym'));
export const MenWalkPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-walk" */ 'Route/Home/Men/Walk'));

export const WomenGymPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-women-gym" */ 'Route/Home/Women/Gym'));
export const WomenRunPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-women-run" */ 'Route/Home/Women/Run'));
export const WomenWalkPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-women-walk" */ 'Route/Home/Women/Walk'));

export const ShoeFinder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "shoefinder" */ 'Route/ShoeFinder'));
export const OurStory = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "our-story" */ 'Route/OurStory/OurStory.component'));

export const CategoryFLite = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "f-lite" */ 'Component/CategoryFLite'));

/** @namespace Inov8/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    [RouterItemType.SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ ({ match }) => <HomePage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 10,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ ({ match }) => <SearchPage match={ match } /> } />,
            position: 25,
            name: RouterSwitchItemType.SEARCH,
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ ({ match }) => <CmsPage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 40,
            name: RouterSwitchItemType.CMS_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ () => <CartPage /> } />,
            position: 50,
            name: RouterSwitchItemType.CART,
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ ({ match }) => <Checkout match={ match } /> } />,
            position: 55,
            name: RouterSwitchItemType.CHECKOUT,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ () => <PasswordChangePage /> } />,
            position: 60,
            name: RouterSwitchItemType.CHANGE_PASSWORD,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ () => <CreateAccountPage /> } />,
            position: 61,
            name: RouterSwitchItemType.CREATE_ACCOUNT,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ () => <LoginAccountPage /> } />,
            position: 62,
            name: RouterSwitchItemType.LOGIN,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ () => <ForgotPasswordPage /> } />,
            position: 63,
            name: RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ () => <SendConfirmationPage /> } />,
            position: 64,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ () => <ConfirmAccountPage /> } />,
            position: 65,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 70,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 71,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDERS,
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: RouterSwitchItemType.MY_ACCOUNT_DOWNLOADABLE,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_WISHLIST } /> } />,
            position: 73,
            name: RouterSwitchItemType.MY_ACCOUNT_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.ADDRESS_BOOK } /> } />,
            position: 74,
            name: RouterSwitchItemType.MY_ACCOUNT_ADDRESS,
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: RouterSwitchItemType.MY_ACCOUNT_NEWSLETTER,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ ({ match }) => <MyAccount match={ match } /> } />,
            position: 76,
            name: RouterSwitchItemType.MY_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ () => <MenuPage /> } />,
            position: 80,
            name: RouterSwitchItemType.MENU,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ ({ match }) => <WishlistShared match={ match } /> } />,
            position: 81,
            name: RouterSwitchItemType.SHARED_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ () => <ContactPage /> } />,
            position: 82,
            name: RouterSwitchItemType.CONTACT_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/compare') } exact render={ () => <ProductComparePage /> } />,
            position: 83,
            name: RouterSwitchItemType.COMPARE,
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } exact render={ () => <StyleGuidePage /> } />,
            position: 84,
            name: RouterSwitchItemType.STYLE_GUIDE,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ORDER } /> } />,
            position: 90,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_INVOICE } /> } />,
            position: 94,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_REFUND } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route render={ ({ match }) => <UrlRewrites match={ match } location={ location as unknown as Location<HistoryState> } /> } />,
            position: 1000,
            name: RouterSwitchItemType.URL_REWRITES,
        },
        {
            component: <Route path={ withStoreRegex('/shoe-finder') } exact render={ ({ match }) => <ShoeFinder match={ match } /> } />,
            position: 11,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/walking') } exact render={ ({ match }) => <WalkPage match={ match } /> } />,
            position: 12,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/walking/womens') } exact render={ ({ match }) => <WomenWalkPage match={ match } /> } />,
            position: 12,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/walking/mens') } exact render={ ({ match }) => <MenWalkPage match={ match } /> } />,
            position: 13,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/men') } exact render={ ({ match }) => <MenPage match={ match } /> } />,
            position: 14,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/women') } exact render={ ({ match }) => <WomenPage match={ match } /> } />,
            position: 14,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/running') } exact render={ ({ match }) => <RunPage match={ match } /> } />,
            position: 14,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/running/mens') } exact render={ ({ match }) => <MenRunPage match={ match } /> } />,
            position: 14,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/running/womens') } exact render={ ({ match }) => <WomenRunPage match={ match } /> } />,
            position: 14,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/gym') } exact render={ ({ match }) => <GymPage match={ match } /> } />,
            position: 15,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/gym/mens') } exact render={ ({ match }) => <MenGymPage match={ match } /> } />,
            position: 15,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/gym/womens') } exact render={ ({ match }) => <WomenGymPage match={ match } /> } />,
            position: 16,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/about-us/our-story') } exact render={ () => <OurStory /> } />,
            position: 16,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/f-lite') } render={ () => <CategoryFLite /> } />,
            position: 17,
            name: RouterSwitchItemType.HOME,
        },
    ];

    render(): ReactElement {
        return (
            <>
                <Meta />
                <ReactRouter history={ history }>
                    { this.renderSectionOfType(RouterItemType.BEFORE_ITEMS_TYPE) }
                    { this.renderRouterContent() }
                </ReactRouter>
            </>
        );
    }
}

export default RouterComponent;
