/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer,
} from 'SourceComponent/Router/Router.container';
import { RouterContainerProps } from 'SourceComponent/Router/Router.type';
import { getStoreState } from 'SourceUtil/Store';
import { RootState } from 'Util/Store/Store.type';

import paths from '../CustomHeader/CustomHeader.json';
/** @namespace Inov8/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export const pathPattern = new RegExp(`^(/(${paths.validCodes.join('|')}))?/(${paths.paths.join('|')})/?$`);
/** @namespace Inov8/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    componentDidUpdate(_prevProps: RouterContainerProps): void {
        const { isLoading, updateMeta } = this.props;

        if (location.pathname !== '/' && pathPattern.test(location.pathname)) {
            const observer = new MutationObserver((mutations, observer) => {
                const headerElement = document.querySelector('.Header');

                if (headerElement) {
                    const classesToRemove = Array.from(headerElement.classList).filter((className) => className.startsWith('Header-Black-Text'));

                    classesToRemove.forEach((className) => headerElement.classList.remove(className));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });
        }

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (isLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
